<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增用户</el-button
              >
            </el-form-item>
          </el-form>

          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="用户名">
              <el-input
                v-model="searchForm.username"
                size="small"
                placeholder="用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input
                v-model="searchForm.nickname"
                size="small"
                placeholder="昵称"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.id" size="mini" placeholder="请选择所属角色" >
                <el-option v-for="item in gg" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" min-width="120">
      </el-table-column>
      <el-table-column prop="username" label="用户名" min-width="120">
      </el-table-column>
      <el-table-column prop="phone" label="电话号码" min-width="120">
      </el-table-column>
      <el-table-column prop="locked" label="锁定状态" min-width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.locked == 0"> 未锁定 </template>
          <template v-else-if="scope.row.locked == 1"> 已锁定 </template>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="所属角色" min-width="120">
      </el-table-column>
      <el-table-column prop="note" label="备注" min-width="120">
      </el-table-column>
      <el-table-column
        prop="yyCompanyName"
        label="所属邀约单位"
        min-width="120"
      >
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            v-if="scope.row.roleName == '疫情防控管理人'"
            type="primary"
            @click="handchakan(scope.row)"
            >查看管理人员</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button size="mini" type="primary" @click="shenhe(scope.row)"
            >审核</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增用户' : '修改用户'"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="昵称" label-width="100px" prop="nickname">
          <el-input
            v-model="editForm.nickname"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input
            v-model="editForm.username"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input
            v-model="editForm.password"
            size="small"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="电话号码" label-width="100px" prop="phone">
          <el-input
            v-model="editForm.phone"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="role">
          <el-select v-model="editForm.role" size="small" placeholder="请选择">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属邀约单位"
          label-width="100px"
          size="small"
          prop="yyCompanyId"
          v-if="editForm.role == 2 || editForm.role == 5 || editForm.role == 10"
        >
          <el-select v-model="editForm.yyCompanyId" placeholder="请选择">
            <el-option
              v-for="item in yyCompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="联络员"
          label-width="100px"
          size="small"
          prop="yyCompanyId"
          v-if="editForm.role == 6"
        >
          <el-select
            v-model="editForm.frontUserId"
            size="small"
            clearable
            filterable
            placeholder="直接选择或搜索选择"
          >
            <el-option
              v-for="(item, index) in frontUserList"
              :key="item.id"
              :label="
                item.nickname +
                '(' +
                item.phoneNum +
                ') - ' +
                (item.adminUserId == null ? '未绑定' : '已绑定')
              "
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="锁定状态"
          size="small"
          label-width="100px"
          prop="phoneNum"
        >
          <el-checkbox v-model="editForm.locked">锁定</el-checkbox>
        </el-form-item>
        <el-form-item label="备注" size="small" label-width="100px">
          <el-input v-model="editForm.note" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
      0
    </el-dialog>
    <chakan ref="chakan" @fetch-data="getDataList(currentPage)" />
  </div>
</template>

<script>
import { juese } from "../../../api/imagelibrary"
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import {
  getUserList,
  addUser,
  updateUser,
  deleteUser,
  shenhe,
} from "@/api/user";
import { getAllRole } from "@/api/role";
import { getYycompanyList } from "../../../api/yy_company";
import { getFrontUserList } from "../../../api/front_user";
import chakan from "../model/chakan.vue";

export default {
  name: "userManage",
  components: { chakan },

  data() {
    return {
      gg:[],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        nickname: "",
        username: "",
        password: "",
        locked: "",
        note: "",
        role: "",
        yyCompanyId: "",
        phone: "",
        frontUserId: "",
      },
      editRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "blur" }],
        phone: [
          {
            validator: validPhone,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
      },
      loadingDiv: null,
      searchForm: {
        username: "",
        nickname: "",
        id:''
      },
      roleList: [],
      yyCompanyList: [],
      frontUserList: [],
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    juese()
    .then((res) => {
      console.log("东方饭店发的东方饭店");
      console.log(res.data);
      this.gg = res.data;
    })
    .catch((err) => {
      console.log(err)
    })

    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
  },
  methods: {
    handchakan(row) {
      this.$refs["chakan"].showEdit(row);
    },
    shenhe(row) {
      console.log(row);
      console.log(row.statues);
      if (row.statues == 1) {
        this.$alert("该用户已经审核成功了！", "标题名称", {
          confirmButtonText: "确定",
        });

        return;
      }
      this.$confirm("用户姓名: " + row.username + " ,确认审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loadingDiv = util.showLoading("设置审核通过中");
          shenhe({ id: row.id })
            .then((res) => {
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                console.log(res.msg);
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.getDataList(this.currentPage);
                });
              }
              loadingDiv.close();
            })
            .catch((res) => {
              loadingDiv.close();
            });
        })
        .catch(() => {});
    },
    init() {
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
        }
      });
    },
    getAllRole() {
      getAllRole().then((res) => {
        if (res.code == 0) {
          this.$alert("获取角色列表失败", "", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }).then(() => {
            this.editFormVisible = false;
          });
        } else if (res.code == 1) {
          this.roleList = res.data;
          console.log(res, "2111111");
        }
      });
    },
    showAddDialogForm() {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.nickname = "";
      this.editForm.username = "";
      this.editForm.note = "";
      this.editForm.password = "";
      this.editForm.locked = 0;
      this.editForm.phone = "";
      this.editFormVisible = true;
      this.roleList = [];
      this.editForm.frontUserId = "";
      this.getAllRole();
    },
    handleEdit(index, row) {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      this.getAllRole();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = row.id;
      this.editForm.nickname = row.nickname;
      this.editForm.username = row.username;
      if (row.roleId <= 0) {
        this.editForm.role = "";
      } else {
        this.editForm.role = row.roleId;
      }
      this.editForm.note = row.note;

      if (row.locked) {
        this.editForm.locked = true;
      } else {
        this.editForm.locked = false;
      }
      this.editForm.password = "";
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.phone = row.phone;
      this.editForm.frontUserId = row.frontUserId;

      this.editFormVisible = true;
    },
    submitForm() {
      // 2 邀约单位 5 邀约人员
      if (
        this.editForm.role == 2 ||
        this.editForm.role == 5 ||
        this.editForm.role == 10
      ) {
        if (util.isEmpty(this.editForm.yyCompanyId)) {
          this.$alert("请选择邀约单位");
          return;
        }
      } else {
        this.editForm.yyCompanyId = "";
      }

      // 6 联络员
      if (this.editForm.role == 6) {
        if (util.isEmpty(this.editForm.frontUserId)) {
          this.$alert("请选择联络员");
          return;
        }
      } else {
        this.editForm.frontUserId = "";
      }

      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateUser;
          if (this.editForm.id == 0) {
            func = addUser;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除用户:" + row.nickname + "(用户名:" + row.nickname + ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteUser({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getUserList({
        username: this.searchForm.username,
        nickname: this.searchForm.nickname,
        roleId: this.searchForm.id,
        pageSize: this.pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
</style>
